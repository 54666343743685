import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import BottomCta from '../../components/bottom-cta'

// Load Page Content
import contentEn from '../../../content/pages/products/whatsapp-business.en.yaml'
import contentId from '../../../content/pages/products/whatsapp-business.id.yaml'
import OtherProducts from '../../components/other-products'
import StudyCaseSlider, { AllStudyCaseType } from '../../components/study-case'
import SEO from '../../components/seo'

export const query = graphql`
  query WhatsappBusinessQuery($language: String!) {
    locales: allLocale(filter: { ns: { in: ["links", "products"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    whatsappStudyCase: allStudyCasesWhatsapp {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    voiceStudyCase: allStudyCasesVoice {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    crmStudyCase: allStudyCasesCrm {
      edges {
        node {
          id
          languages {
            id
            description
            locale
            logo
            title
          }
        }
      }
    }
    logo: file(relativePath: { eq: "images/whatsapp-business/whatsapp-business-logo.svg" }) {
      id
      publicURL
    }
    pointIcon: file(relativePath: { eq: "images/whatsapp-business/whatsapp-business-point.svg" }) {
      id
      publicURL
    }
    figureWhyUseWhatsapp: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-figure-why-use-whatsapp.png" }
    ) {
      id
      publicURL
    }
    generalInquiryIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-general-inquiry.svg" }
    ) {
      id
      publicURL
    }
    askForProductIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-ask-for-product.svg" }
    ) {
      id
      publicURL
    }
    makeAPurchaseIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-make-a-purchase.svg" }
    ) {
      id
      publicURL
    }
    makeAppointmentIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-make-appointment.svg" }
    ) {
      id
      publicURL
    }
    serviceSupportIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-service-support.svg" }
    ) {
      id
      publicURL
    }
    productRelatedIcon: file(
      relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-product-related-reviews.svg" }
    ) {
      id
      publicURL
    }
    checkIcon: file(relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-available.svg" }) {
      id
      publicURL
    }
    uncheckIcon: file(relativePath: { eq: "images/whatsapp-business/whatsapp-business-icon-unavailable.svg" }) {
      id
      publicURL
    }
  }
`

interface whatsappBusinessPagePropsType {
  data: {
    whatsappStudyCase: AllStudyCaseType
    voiceStudyCase: AllStudyCaseType
    crmStudyCase: AllStudyCaseType
    logo: PublicUrlType
    pointIcon: PublicUrlType
    figureWhyUseWhatsapp: PublicUrlType
    generalInquiryIcon: PublicUrlType
    askForProductIcon: PublicUrlType
    makeAPurchaseIcon: PublicUrlType
    makeAppointmentIcon: PublicUrlType
    serviceSupportIcon: PublicUrlType
    productRelatedIcon: PublicUrlType
    checkIcon: PublicUrlType
    uncheckIcon: PublicUrlType
  }
}

const WhatsappBusinessPage = (props: whatsappBusinessPagePropsType): JSX.Element => {
  const { t, i18n } = useTranslation()
  const [content, setContent] = useState(contentEn)
  const {
    logo,
    whatsappStudyCase,
    voiceStudyCase,
    crmStudyCase,
    pointIcon,
    figureWhyUseWhatsapp,
    generalInquiryIcon,
    askForProductIcon,
    makeAPurchaseIcon,
    makeAppointmentIcon,
    serviceSupportIcon,
    productRelatedIcon,
    checkIcon,
    uncheckIcon,
  } = props.data

  useEffect(() => {
    switch (i18n.language) {
      case 'id':
        setContent(contentId)
        break
      case 'en':
        setContent(contentEn)
        break
      default:
        setContent(contentEn)
        break
    }
  }, [i18n.language])

  return (
    <>
      <SEO title={content.title} description={content.description} lang={i18n.language}></SEO>
      {/* Start Hero Section */}
      <section className="uk-light">
        <div
          className="uk-section uk-background-norepeat uk-background-cover tm-whatsapp-business-hero-background uk-flex uk-flex-middle"
          uk-height-viewport=""
          uk-img=""
          style={{ minHeight: 'calc(100vh)' }}
        >
          <div className="uk-width-1-1">
            <div className="uk-container">
              <div className="uk-flex uk-flex-between" uk-grid="">
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div className="uk-animation-slide-top-medium uk-text-center uk-text-left@m">
                    <img className="uk-hidden@m" width="125" alt="" uk-img={logo.publicURL} />
                    <h1 className="uk-heading-small">{content.title}</h1>
                    <p className="uk-visible@m uk-text-lead">{content.description}</p>
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-right uk-visible@m">
                  <img width="" height="" alt="" uk-img={logo.publicURL} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}

      <section id="whatsapp" className="uk-section uk-section-large">
        <div className="uk-with-1-1">
          <div className="uk-container">
            <div className="uk-width-xlarge uk-text-center uk-margin-auto">
              <h2>{content.whatsapp_business_api.title}</h2>
            </div>

            <section className="uk-section">
              <div className="uk-grid uk-child-width-1-3@m" uk-grid="">
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.one_number_for_everyone.title}</h3>
                    <p>{content.whatsapp_business_api.one_number_for_everyone.description}</p>
                  </div>
                </div>
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.verified_account.title}</h3>
                    <p>{content.whatsapp_business_api.verified_account.description}</p>
                  </div>
                </div>
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.database_management.title}</h3>
                    <p>{content.whatsapp_business_api.database_management.description}</p>
                  </div>
                </div>
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.performance_reports.title}</h3>
                    <p>{content.whatsapp_business_api.performance_reports.description}</p>
                  </div>
                </div>
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.integrated_api.title}</h3>
                    <p>{content.whatsapp_business_api.integrated_api.description}</p>
                  </div>
                </div>
                <div className="uk-flex">
                  <div className="uk-width-auto uk-margin-right">
                    <img width={25} src={pointIcon.publicURL} style={{ marginTop: 4 }} />
                  </div>
                  <div className="uk-width-expand">
                    <h3>{content.whatsapp_business_api.bulk_outbond_message.title}</h3>
                    <p>{content.whatsapp_business_api.bulk_outbond_message.description}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="uk-section">
              <div uk-grid="">
                <div className="uk-width-1-2@m">
                  <div className="uk-flex uk-flex-center">
                    <img src={figureWhyUseWhatsapp.publicURL} alt="" />
                  </div>
                </div>
                <div className="uk-width-1-2@m uk-flex uk-flex-middle">
                  <div>
                    <h3>{content.whatsapp_business_api.why_use_whatsapp.title}</h3>
                    <p>{content.whatsapp_business_api.why_use_whatsapp.description}</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="uk-section">
              <div className="uk-width-xlarge uk-text-center uk-margin-auto uk-margin-large-bottom">
                <h3>{content.whatsapp_business_api.how_business_use_whatsapp.title}</h3>
              </div>
              <div className="uk-grid uk-child-width-1-3@m uk-text-center uk-grid-divider" uk-grid="">
                <div>
                  <h4>{content.whatsapp_business_api.how_business_use_whatsapp.pre_purchase.title}</h4>
                  <div className="uk-flex uk-flex-center">
                    <div className="uk-padding-small">
                      <img width="80" src={generalInquiryIcon.publicURL} />
                      <p className="uk-h2">84%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.pre_purchase.contentOne}</p>
                    </div>
                    <div className="uk-padding-small">
                      <img width="80" src={askForProductIcon.publicURL} />
                      <p className="uk-h2">81%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.pre_purchase.contentTwo}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>{content.whatsapp_business_api.how_business_use_whatsapp.purchase.title}</h4>
                  <div className="uk-flex uk-flex-center">
                    <div className="uk-padding-small">
                      <img width="80" src={makeAPurchaseIcon.publicURL} />
                      <p className="uk-h2">74%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.purchase.contentOne}</p>
                    </div>
                    <div className="uk-padding-small">
                      <img width="80" src={makeAppointmentIcon.publicURL} />
                      <p className="uk-h2">71%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.purchase.contentTwo}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <h4>{content.whatsapp_business_api.how_business_use_whatsapp.post_purchase.title}</h4>
                  <div className="uk-flex uk-flex-center">
                    <div className="uk-padding-small">
                      <img width="80" src={serviceSupportIcon.publicURL} />
                      <p className="uk-h2">76%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.post_purchase.contentOne}</p>
                    </div>
                    <div className="uk-padding-small">
                      <img width="80" src={productRelatedIcon.publicURL} />
                      <p className="uk-h2">79%</p>
                      <p>{content.whatsapp_business_api.how_business_use_whatsapp.post_purchase.contentTwo}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="uk-section">
              <div className="uk-width-xlarge uk-text-center uk-margin-auto uk-margin-large-bottom">
                <h3>{content.whatsapp_business_api.whatsapp_business_vs_official.title}</h3>
              </div>

              <div className="uk-card uk-card-default uk-card-small tm-price-table uk-margin-auto">
                <table className="uk-table uk-table-hover uk-table-divider">
                  <thead>
                    <tr>
                      {content.whatsapp_business_api.whatsapp_business_vs_official.table_title.map(
                        (content: any, index: number) => (
                          <th className={index !== 0 ? 'uk-text-center' : undefined}>{content}</th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.busines_profile}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.message_template}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.up_to}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.unlimited}
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {
                          content.whatsapp_business_api.whatsapp_business_vs_official.table_content
                            .customer_and_chat_history
                        }
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.database_management}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {
                          content.whatsapp_business_api.whatsapp_business_vs_official.table_content
                            .bulk_outbound_messages
                        }
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.performance_reports}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {
                          content.whatsapp_business_api.whatsapp_business_vs_official.table_content
                            .official_business_checkmark
                        }
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.powerful_sales}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                    <tr>
                      <td className="uk-width-1-3">
                        {content.whatsapp_business_api.whatsapp_business_vs_official.table_content.service_system}
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={uncheckIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                      <td className="uk-width-1-3 uk-text-center">
                        <img src={checkIcon.publicURL} width={`24px`} height={`24px`} />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>

        <StudyCaseSlider data={whatsappStudyCase} language={i18n.language} />
      </section>
    </>
  )
}

export default WhatsappBusinessPage
